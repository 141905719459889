import React, { Component } from "react";
import Countdown from "./Countdown";
import { Navbar, Container, Col, Row } from "react-bootstrap";

class App extends Component {
  render() {
    return (
      <Navbar expand="lg" variant="light" fixed="top" className="purple countdownWidth">
        <Container fluid>
          <Row>
            <Col lg={12} xs={12}>
              <div className="text-App">
                <p>Penawaran Akan Berakhir : </p>
              </div>
            </Col>
            <Col lg={12} xs={12}>
              <div className="App">
                <Countdown date={`November 24, 2023 23:59:59`} />
              </div>
            </Col>
          </Row>
        </Container>
      </Navbar>
    );
  }
}

export default App;
