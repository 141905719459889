import React from "react";
import "../assets/css/style-newsalespage.css";
import DataTestimoni from "../assets/data/testimoni.json";
import DataLogoMedia from "../assets/data/media-converence.json";
import DataListParagraf from "../assets/data/listing-paragraf.json";
import DataListSecret from "../assets/data/listing-secret.json";
import DataBenfit from "../assets/data/listing-benefit.json";
import DataFAQ from "../assets/data/faq.json";
import { Accordion, Card, Button } from "react-bootstrap";
import App from "./App";



export default function Index() {
  
  const scrollToElement = () => {
    const element = document.getElementById('ordercm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return(
    <>
      <main className="main-width">
      <App/>
      {/* first section */}
        <div className="container __bg-primary-new">
          <div className="flex-column justify-content-center h-100">
            {/* image logo trulav and zoom */}
            <div className="d-flex justify-content-center align-items-center">
              <img 
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/Trulav-Zoom-Logo.png"
                alt="logo trulav and zoom"
                className="img img-fluid __img-logo-tr-zoom"
              />
            </div>
            {/* image logo commitement mastery */}
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/Logo-CM.png"
                alt="logo CM"
                className="img img-fluid mt-4 __img-logo-cm"
              />
            </div>
            <div className="d-flex justify-content-center my-3">
              <h2
                className="text-center __font-headline-sl-cm"
              >
                Teknik Agar Pria Mau Komit Tanpa Paksaan Tanpa Pertengkaran
              </h2>
            </div>
            {/* image mockup commitment */}
            <div className="d-flex justify-content-center my-3">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/Mockup-Device-CM.png"
                className="img img-fluid __img-mockup-cm"
              />
            </div>
            {/* text info webinar */}
            <div className="d-flex justify-content-center">
              <p className="text-center __text-style-info-wb">
                  Diadakan secara langsung via Zoom<br/>
                  Sabtu 25 November 2023<br/>
                  09.00 WIB - 12.00 WIB
              </p>
            </div>
            {/* button learn more */}
            <div className="d-flex justify-content-center align-items-center">
              <button 
                className="btn btn-danger __custome-button-cm-wb" onClick={scrollToElement}>
                  Ya Saya Mau Daftar!
                </button>
            </div>
          </div>
        </div>
      {/* end first section */}
      {/* second section */}
        <div className="container bg-white py-5">
          <div className="flex-column justify-content-center text-black">
            {/* text headline second section */}
            <div className="d-flex justify-content-center">
              <h2 
                className="text-center __text-headline-sl-cm">
                STOP!! <br/> Simak beberapa <br/> testimoni alumni <br/> Commitment Mastery <br/> sebelumnya.
              </h2>
            </div>
            {/* card testimoni */}
            <div className="d-flex flex-column justify-content-center align-items-center my-4">
              {DataTestimoni.items.map((item, index) => (
                <div className="card __width-testi-card my-3" key={index}>
                  <div className="card-body px-5 py-4">
                    <p className="text-left __style-paragraf-testi">
                     { item.text }
                    </p>
                    <p className="text-left __style-naming-testi">
                      { item.name }
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {/* dsb */}
            <div className="d-flex justify-content-center">
              <p className="text-center __text-dsb-testi">
                Dan <b>ratusan testimoni</b> lainnya..
              </p>
            </div>
          </div>
        </div>
      {/* end second section */}
      {/* theree section */}
        <div className="container bg-white py-5">
          <div className="d-flex flex-column justify-content-center">
            {/* image jose */}
            <div className="d-flex justify-content-center">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/Image-jose.jpg"
                alt="image jose"
                className="img img-fluid __imge-jose-style"
              />
            </div>
            {/* short bio jose */}
            <div className="d-flex flex-column justify-content-center mb-4">
              <p className="text-center __headline-custome-bio-jose pt-4">
                Dibawakan oleh Jose Aditya, Love Life Coach Khusus Wanita #1 Indonesia
              </p>
              <p className="text-center __text-custome-bio-jose pt-4">
                Jose Aditya adalah seorang praktisi Hypnotherapist dan juga mendalami Neuro Language Programming, Neuro Associative Conditioning, dan Neuro-Semantic sebagai Master Practitioner.
                Impiannya kini adalah membantu 1 juta wanita bahagia di kehidupan cintanya.
                Karena dia percaya, wanita yang bahagia bisa membuat dunia jadi lebih indah.
              </p>
            </div>
             {/* button learn more */}
             <div className="d-flex justify-content-center align-items-center">
              <button 
                className="btn btn-danger __custome-button-cm-wb" onClick={scrollToElement}>
                 Daftar Sekarang
                </button>
            </div>
            {/* media coverage */}
            <div className="d-flex flex-column justify-content-center align-items-center my-5">
              {/* headline media coverage */}
              <div className="d-flex justify-content-ceter">
                <h2 className="text-center __media-coverage">
                  Media Coverage
                </h2>
              </div>
              {/* logo media coverage */}
              <div className="row justify-content-center align-items-center gap-5 mt-4">
                {DataLogoMedia.items.map((item, index) => (
                  <div className="col-lg-6 col-12 text-center" key={index}>
                    <img
                      src={item.imageurl}
                      alt="image media 1"
                      className="img img-fluid mt-4 __style-media-converance"
                    />
                  </div>
                ))} 
                </div>
            </div>
          </div>
        </div>
      {/* end theree section */}
      {/* four section */}
        <div className="container bg-white pb-5">
          <div className="d-flex flex-column justify-content-center">
            {/* headline media mungkin sudah sadar */}
              <div className="d-flex justify-content-center">
                <h2 className="text-center __media-coverage">
                  Mungkin Kamu Sudah <br/> Sadar…
                </h2>
              </div>
            {/* paragraf mungkin sadar */}
              <div className="d-flex justify-content-center">
                <p className="text-center __style-paragrap-mukin-sadar">
                  Banyak wanita <b>frustasi calon pasangannya tidak kunjung bertindak,</b> Banyak wanita stress karena <b>pasangannya janji akan berubah setelah menikah</b> tapi faktanya malah makin parah.
                </p>
              </div>
            {/* image mungkin sadar */}
              <div className="d-flex justify-content-center my-5">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/image-mungkin-sadar.jpg"
                  className="img img-fluid __width-image-sadar-diri"
                  alt="image mungkin sadar"
                />
              </div>
            <div className="w-100 "></div>
            {/* paragraf mungkin sadar */}
              <div className="d-flex justify-content-center py-3">
                <p className="text-center __style-paragrap-mukin-sadar">
                  Sementara hubunganmu dengannya sudah mulai diambang “kebosanan”, Kamu masih belum juga menemukan cara bagaimana 
                </p>
              </div>
            {/* paragraf mungkin sadar */}
              <div className="d-flex justify-content-center py-3">
                <p className="text-center __style-paragrap-mukin-sadar">
                  <b>
                    MENGGIRING DIA KE ARAH LEBIH SERIUS.
                  </b>
                </p>
              </div>
            {/* paragraf mungkin sadar */}
              <div className="d-flex justify-content-center py-3">
                <p className="text-center __style-paragrap-mukin-sadar">
                  Dan tanpa Kamu sadari, kamu sendiri sebenarnya masih belum paham..
                </p>
              </div>
            {/* paragraf mungkin sadar */}
              <div className="d-flex justify-content-center py-3">
                <p className="text-center __style-paragrap-mukin-sadar">
                  <b>Apa yang membuatnya terus menunda</b> sementara kalian sudah cukup akrab,
                  Bagaimana kamu bisa <b>menanam ide komitmen TANPA TERLIHAT ngebet nikah,</b> Dan <b>apakah dia pria yang tepat</b> untuk kamu ajak <b>berpetualang menjalani pernikahan seumur hidup?</b>
                </p>
              </div>
          </div>
        </div>
      {/* end four section */}
      {/* five section */}
        <div className="container bg-white pb-5">
          <div className="d-flex flex-column justify-content-center">
            {/* headline media mungkin sudah sadar */}
              <div className="d-flex justify-content-center">
                <h2 className="text-center __media-coverage">
                  Apakah Kamu pernah <br/> mengalami salah satu <br/> dari masalah di bawah <br/> ini?
                </h2>
              </div>
            {/* list paragraf */}
            <div className="d-flex flex-column justify-content-center">
              <ul className="__ul-width-custome-style">
                {DataListParagraf.items.map((item, index) => (
                <li key={index}>
                  <div dangerouslySetInnerHTML={{__html:item.paragraf}}/>
                </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      {/* end five section */}
      {/* section six */}
        <div className="container __bg-primary-new py-5">
          <div className="d-flex flex-column justify-content-center">
          {/* headline media mungkin sudah sadar */}
            <div className="d-flex justify-content-center">
              <h2 className="text-center __media-coverage">
                Apa itu Webinar <br/> Commitment Mastery?
              </h2>
            </div>
          {/* paragraf mungkin sadar */}
            <div className="d-flex justify-content-center">
              <p className="text-center __style-paragrap-mukin-sadar">
                Dalam webinar ini kamu akan belajar <br/> <b>TEKNIK BANGUN HUBUNGAN yang  MEMANCING PRIA
                untuk MAU KOMIT TANPA PAKSAAN</b>
              </p>
            </div>
          {/* image mockup commitment */}
            <div className="d-flex justify-content-center mt-5">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/Mockup-Device-CM.png"
                className="img img-fluid __img-mockup-cm"
              />
            </div>
          {/* paragraf mungkin sadar */}
            <div className="d-flex justify-content-center">
              <p className="text-center __style-paragrap-mukin-sadar">
              Mengapa online training Commitment Mastery ini wajib kamu ikuti..
              </p>
            </div>
          {/* list paragraf */}
            <div className="d-flex flex-column justify-content-center pt-5">
              <ul className="__ul-width-custome-style">
                {DataListParagraf.items2.map((item, index) => (
                <li key={index} className="list-none">
                  <div className="d-flex justify-content-center">
                  <img
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/icon-hand.png"
                    className="img img-fluid __icon-custome-style"
                    alt="icon"
                  />   
                  <div className="pl-3" dangerouslySetInnerHTML={{__html:item.paragraf}}/>
                  </div>
                </li>
                ))}
              </ul>
            </div>
          {/* paragraf mungkin sadar */}
            <div className="d-flex justify-content-center pt-3">
                <p className="text-center __style-paragrap-mukin-sadar">
                  <i>
                    Dan itu <b>semua bisa terjadi</b> ketika kamu <b>kuasai sebuah teknik elegan dan efektif bikin dia mau komit</b> tanpa paksaan, tanpa pertengkaran..
                  </i>
                </p>
            </div>
          </div>
        </div>
      {/* end section six */}
      {/* section seven */}
        <div className="container bg-white py-5">
          <div className="d-flex flex-column justify-content-center">
            {/* card testimoni */}
              <div className="d-flex flex-column justify-content-center align-items-center my-4">
                  {DataTestimoni.item2.map((item, index) => (
                    <div className="card __width-testi-card my-3" key={index}>
                      <div className="card-body px-5 py-4">
                        <p className="text-left __style-paragraf-testi">
                        { item.text }
                        </p>
                        <p className="text-left __style-naming-testi">
                          { item.name }
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
          </div>
          {/* section about cm */}
            <div className="d-flex flex-column justify-content-center mt-5">
                <div className="card __width-testi-card my-3">
                    <div className="card-body px-4 py-4">
                      <p className="text-left __style-naming-testi text-center">
                        Mengapa Commitment Mastery berguna untuk mereka yang belum punya pasangan?
                      </p>
                      <div className="d-flex justify-content-center">
                        <img
                          src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/section-6-1.jpg"
                          alt="img section 6 1" 
                          className="img img-fluid"/>
                      </div>
                      <p className="text-left __style-paragrap-mukin-sadar text-center">
                        Agar wanita single bisa memancing komitmen pria BAHKAN sejak pengenalan dan sebelum cinta itu datang. Membuat ide tentang komitmen ini jadi begitu memikat di otak pria, sehingga hubungan bisa lebih mulus untuk maju ke jenjang pernikahan.
                      </p>
                    </div>
                </div>
                <div className="card __width-testi-card my-3">
                    <div className="card-body px-4 py-4">
                      <p className="text-left __style-naming-testi text-center">
                        Bagaimana Commitment Mastery juga bisa membantu mereka yang sudah punya pasangan?
                      </p>
                      <div className="d-flex justify-content-center">
                        <img
                          src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/image-6-2.jpg"
                          alt="img section 6 1" 
                          className="img img-fluid"/>
                      </div>
                      <p className="text-left __style-paragrap-mukin-sadar text-center">
                        Agar wanita bisa “membantu” pasangannya untuk TERSADAR bahwa komitmen ini membahagiakan, bukan sebuah kesengsaraan. Dan pada akhirnya mereka inisiatif untuk terus meningkatkan komitmennya. Sehingga kamu bisa keluar dari hubungan yang membosankan, menuju hubungan yang penuh kebahagiaan.
                      </p>
                    </div>
                </div>
            </div>
          {/* section secret */}
            <div className="d-flex flex-column justify-content">
              {DataListSecret.items.map((item, index) => (
                <div className="card __width-testi-card my-3" key={index}>
                    <div className="card-body px-3 py-4">
                      <h1 className="text-center">
                        {
                          item.title
                        }
                      </h1>
                      <ul className="__ul-width-custome-style">
                        {item.secrets.map((s, i) => (
                          <li key={i} className="list-none">
                            <div className="d-flex justify-content-center">
                            <img
                              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/Checklist.png"
                              className="img img-fluid __icon-custome-style"
                              alt="icon"
                            />   
                            <p className="text-left pl-3 __style-paragraf-testi-2">
                              {s.text}
                            </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                </div>
              ))}
            </div>
          {/* button learn more */}
            <div className="d-flex justify-content-center align-items-center">
              <button className="btn btn-danger __custome-button-cm-wb" onClick={scrollToElement}>
                Mau Ikut Commitment Mastery Please
              </button>
            </div>
        </div>
      {/* end section seven */}
      {/* section eight */}
        <div className="container bg-white py-5">
          <div className="d-flex flex-column justify-content-center">
            {/* headline media mungkin sudah sadar */}
            <div className="d-flex justify-content-center">
                <h2 className="text-center __media-coverage">
                  Apa Saja yang Akan Kamu Akan Dapatkan Ketika Join Kelas Ini:
                </h2>
            </div>
            {/* benefit */}
            <div className="d-flex flex-column justify-content-center mt-4">
              {DataBenfit.items.map((item, index) => (
                <div className="card __width-testi-card my-3" key={index}>
                    <div className="card-body px-4 py-4">
                      <p className="__style-naming-testi text-center">
                       {item.title}
                      </p>
                      <div className="d-flex justify-content-center">
                        <img
                          src={item.imgUrl}
                          alt="img section 6 1" 
                          className="img img-fluid"/>
                      </div>
                      <p className="__style-naming-testi text-center" >
                        {item.schedule}
                      </p>
                    
                      <ul className="__ul-width-custome-style">
                        {item.list.map((a, i) => (
                          <li key={i}>
                            <div className="d-flex justify-content-start">
                              <p className="text-left __style-paragraf-testi-2">
                                {a.text}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <p className="__style-naming-testi text-center text-danger py-3">
                       {item.priceles}
                      </p>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      {/* end section eight */}
      {/* strategic thinking */}
        <div className="container bg-white py-5">
          <div className="d-flex flex-column justify-content-center">
             {/* headline media mungkin sudah sadar */}
              <div className="d-flex flex-column justify-content-center">
                  <p className="text-center __style-paragrap-mukin-sadar">
                    Mungkin di titik ini, Kamu mulai berpikir:
                  </p>
                  <p className="text-center __style-paragrap-mukin-sadar">
                    <b>
                      AAAAK GAK SABAR PENGEN CEPET BELAJAR!!
                    </b>
                  </p>
              </div>
             {/* image banner */}
              <div className="d-flex justify-content-center align-items-center">
                <img 
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/Banner-CM.jpg"
                  alt="logo trulav and zoom"
                  className="img img-fluid __img-logo-tr-zoom my-4"
                />
              </div>
             {/* headline media mungkin sudah sadar */}
              <div className="d-flex flex-column justify-content-center">
                <h4 className="text-center __media-coverage">
                  Join Commitment Mastery Live Webinar Sekarang!
                </h4>
                <h4 className="text-center __media-coverage">
                 Sabtu, 25 November 2022 - pukul 09.00 - 12.00 WIB
                </h4>
              </div>
             {/* image mockup commitment */}
              <div className="d-flex justify-content-center mt-5">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/All-Mockup-CM.jpg"
                  className="img img-fluid __img-mockup-cm"
                />
              </div>
            {/* listing */}
              <div className="d-flex justify-content-center">
                <ul className="__ul-width-custome-style">
                  {DataListParagraf.items3.map((s, i) => (
                    <li key={i} className="list-none">
                      <div className="d-flex justify-content-start">
                        <img
                          src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/TruLav.id/Landing_Page/CM/new-page/Checklist.png"
                          className="img img-fluid __icon-custome-style"
                          alt="icon"
                        />   
                        <div className="text-left pl-3 __style-paragraf-testi-2" dangerouslySetInnerHTML={{__html:s.paragraf}}/>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            {/* headline media mungkin sudah sadar */}
              <div className="d-flex flex-column justify-content-center pt-5" id="ordercm">
                  <p className="text-center __style-paragrap-mukin-sadar">
                    Dengan materi sekeren dan bonus sebanyak ini Kamu mungkin berpikir perlu investasi mahal untuk join online training ini.
                  </p>
                  <p className="text-center __style-paragrap-mukin-sadar pt-4">
                    Tenang… Karena Nyatanya Tidak Semahal Itu.
                  </p>
                  <h4 className="text-center __media-coverage pt-5">
                    <del>Rp 1.154.000</del>
                  </h4>
                  <h2 className="text-center __media-coverage text-danger mb-0" style={{fontSize:"65px"}}>
                    Rp 269.000
                  </h2>
                  <p className="text-center __style-paragrap-mukin-sadar">
                    (Hematnya 885ribu - 76℅ Off!)
                  </p>
              </div>
            {/* button learn more */}
              <div className="d-flex justify-content-center align-items-center">
                <a href="https://trulavid.orderonline.id/commitmentmastery" target="_blank" rel="noreferrer" className="btn btn-danger __custome-button-cm-wb oo-link">
                    Daftar Sekarang
                  </a>
              </div>
            {/* headline media mungkin sudah sadar */}
              {/* <div className="d-flex flex-column justify-content-center pt-5">
                  <p className="text-center __style-paragrap-mukin-sadar">
                    Tapi tunggu dulu, sambil Kamu gak sabar untuk <b>klik button join, 
                    KHUSUS PENDAFTAR  SEBELUM TANGGAL 18 November <br/><br/> Dapatkan DISKON TAMBAHAN 60rb! <br/><br/> Masukan kode kupon: CMHEMAT60</b>
                  </p>
              </div> */}
          </div>
        </div>
      {/* end strategic thinking */}
      {/* section testimoni */}
        <div className="container bg-white py-5">
            <div className="d-flex flex-column justify-content-center">
              {/* headline media mungkin sudah sadar */}
                <div className="d-flex justify-content-center">
                  <h2 className="text-center __media-coverage">
                  <i>
                    Lihat dan rasakan testimoni bahagia dari para alumni Commitment Master Batch sebelumnya..
                  </i>
                  </h2>
                </div>
              {/* card testimoni */}
                <div className="d-flex flex-column justify-content-center align-items-center my-4">
                    {DataTestimoni.items3.map((item, index) => (
                      <div className="card __width-testi-card my-3" key={index}>
                        <div className="card-body px-5 py-4">
                          <p className="text-left __style-paragraf-testi">
                          { item.text }
                          </p>
                          <p className="text-left __style-naming-testi">
                            { item.name }
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
            </div>
        </div>
      {/* end section testimoni */}
      {/* list benefit */}
        <div className="container bg-white py-5">
          <div className="d-flex flex-column justify-content-center">
            {/* headline media mungkin sudah sadar */}
              <div className="d-flex justify-content-center">
                <h2 className="text-center __media-coverage w-75">
                  Kenapa Kamu Wajib Buru-buru Gabung Online Training Ini?
                </h2>
              </div>
              {DataBenfit.items2.map((i, index) => (
                <div className="d-flex flex-column justify-content-center my-3" key={index}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={i.imgUrl}
                      alt="icon"
                      className="img img-fluid"
                      style={{width:"100px", height:"100px"}}
                    />
                  </div>
                  <p className="text-center __style-paragrap-mukin-sadar">
                    <b>
                      {i.title}
                    </b>
                  </p>
                  <p className="text-center __style-paragrap-mukin-sadar" dangerouslySetInnerHTML={{__html:i.text}}/>
                </div>
              ))}
              {/* button learn more */}
              <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-danger __custome-button-cm-wb" onClick={scrollToElement}>
                  Mau Ikut Commitment Mastery Please
                </button>
              </div>
          </div>
        </div>
      {/* end list benefit */}
      {/* faq */}
      <div className="container bg-white py-5">
        <div className="d-flex flex-column justify-content-center">
          {/* headline media mungkin sudah sadar */}
            <div className="d-flex justify-content-center">
              <h2 className="text-center __media-coverage w-75">
                F A Q
              </h2>
            </div>
          {/* list faq */}
          <Accordion>
            {DataFAQ.items.map((result, index) => (
              <Card key={index}>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey={result.id}>
                    <p className="text-left text-dark">{result.q}</p>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={result.id}>
                  <Card.Body>
                    <div className="text-left text-dark" dangerouslySetInnerHTML={{__html:result.a}}/>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
           
        </Accordion>
        </div>
      </div>
      {/* end faq */}
      <p className="text-center bg-white pb-5">
              Copyright © {new Date().getFullYear()} PT Jose Upmind Digital Optima
            </p>
      </main>
    </>
  );
}